import React, { Fragment, useEffect, useState } from "react";

import Nav from "../components/Nav.js";
import config from "../config.js";
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import LoadingSpinner from "../components/LoadingSpinner.js";
import { genericErrorMsg } from "../utils.js";
import Select, { createFilter } from 'react-select';
import { isAdmin, isReception, isSuperAdmin, isFinance } from '../utils';

export default function ReportsPage() {

    const [potl, setPotl] = useState(null);
    const [potlFilter, setPotlFilter] = useState('');

    const [offlandDates, setOfflandDates] = useState(null);

    const [odStartDate, setOdStartDate] = useState(dayjs().startOf('month').toDate());
    const [odEndDate, setOdEndDate] = useState(dayjs().endOf('month').toDate());

    const [occStartDate, setOccStartDate] = useState(dayjs().startOf('month').subtract(3, 'month').toDate());
    const [occEndDate, setOccEndDate] = useState(dayjs().endOf('month').subtract(1, 'month').toDate());

    const [occupancyReport, setOccupancyReport] = useState(null);
    const [loadingOccupancyReport, setLoadingOccupancyReport] = useState(false);

    const occupancyTypeOptions = ['Team', 'Guest', 'Village'].map(p=>{return {value: p, label: p}});
    const [selectedOccTypes, setSelectedOccTypes] = useState([]);

    const [ratesStartDate, setRatesStartDate] = useState(dayjs().startOf('month').subtract(3, 'month').toDate());
    const [ratesEndDate, setRatesEndDate] = useState(dayjs().endOf('month').subtract(1, 'month').toDate());

    const [selectedRateTypes, setSelectedRateTypes] = useState([]);

    const [ratesReport, setRatesReport] = useState(null);
    const [loadingRatesReport, setLoadingRatesReport] = useState(false);


    const fetchOfflandDates = () => {
        fetch(`${config.server_base_url}/api/offlandDates?` + new URLSearchParams({
            startDate: dayjs(odStartDate).format('YYYY-MM-DD'),
            endDate: dayjs(odEndDate).format('YYYY-MM-DD'),
          }))
            .then((response) => response.json())
            .then((data) => setOfflandDates(data.offlandDates))
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
    }    

    const fetchPotlReport = () => {
        fetch(`${config.server_base_url}/api/reports/potl`)
            .then((response) => response.json())
            .then((data) => setPotl(data.potl))
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
    }   
    
    const fetchOccupancyReport = () => {
        setLoadingOccupancyReport(true);
        fetch(`${config.server_base_url}/api/reports/occupancyReport?` + new URLSearchParams({
            startDate: dayjs(occStartDate).format('YYYY-MM-DD'),
            endDate: dayjs(occEndDate).format('YYYY-MM-DD'),
            bookingTypes: selectedOccTypes
          }))
            .then((response) => response.json())
            .then((data) => {setOccupancyReport(data); setLoadingOccupancyReport(false); })
            .catch(error => {toast.error( genericErrorMsg, {theme: 'colored'}); setLoadingOccupancyReport(false); });
    }      

    const fetchRatesReport = () => {
        setLoadingRatesReport(true);
        fetch(`${config.server_base_url}/api/reports/ratesReport?` + new URLSearchParams({
            startDate: dayjs(ratesStartDate).format('YYYY-MM-DD'),
            endDate: dayjs(ratesEndDate).format('YYYY-MM-DD'),
            bookingTypes: selectedRateTypes
          }))
            .then((response) => response.json())
            .then((data) => {setRatesReport(data); setLoadingRatesReport(false); })
            .catch(error => {toast.error( genericErrorMsg, {theme: 'colored'}); setLoadingRatesReport(false); });
    }       

    const filterPotlReport = (potlRow) => {        
        if (potlFilter.trim().length<3) return true;

        const words = potlFilter.toLowerCase().split(' ');

        const searchableText = `${potlRow.guest.preferredName} ${potlRow.guest.firstName} ${potlRow.guest.lastName} ${potlRow.room} ${potlRow.offlandDates.length>0 ? 'offland' : ''}`;

        return !(words.map(w=>searchableText.toLowerCase().includes(w.toLowerCase())).includes(false));
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
      });

    const selectFilterStyles = { menuPortal: (base) => ({ ...base, fontSize: '14px', zIndex: 6 }), control: (base) => ({ ...base, fontSize: '14px', paddingTop: 0 }) };

    useEffect(() => {
        fetchPotlReport();
    }, [])

    useEffect(() => {
        fetchOfflandDates();
    },[odStartDate, odEndDate])    

    useEffect(() => {
        fetchOccupancyReport();
    },[occStartDate, occEndDate, selectedOccTypes])    

    useEffect(() => {
        fetchRatesReport();
    },[ratesStartDate, ratesEndDate, selectedRateTypes])  

    return (
      <div id='app-container' className="app-container">
        <Nav url="/reports"></Nav>
        <div className='page-header'><h1>Reports</h1></div>
        <div id='page-container' className="page-container reports-page">  
            {/* <div className='form-row form-filters'>
                <label>Due payments until</label>
                <DatePicker selected={duePaymentsUntil} onChange={(date) => {setDuePaymentsUntil(date); fetchBookings(date)}} dateFormat="dd MMM, yyyy" calendarStartDay={1} />
            </div> */}
            <Tabs className="mb-3">
                <Tab eventKey="potlReport" title="People on the Land">
                    {potl ?
                        <Fragment>
                            <p className='tab-info-text'>There are {potl.filter(p=>p.offlandDates.length==0).length} people in Sahaja today <span>{dayjs().format('DD MMM, YYYY')}</span>. {potl.filter(p=>p.offlandDates.length>0).length} people are offland.</p>
                            <div className='data-table-container'>
                                <div className='table-actions table-filters'>
                                    <div className="potl-filter">
                                        <Form.Control name="potlFilter" type="text" value={potlFilter} onChange={(e)=> setPotlFilter(e.target.value)} placeholder='Search...' />
                                    </div>
                                </div>
                                <Table striped bordered hover className='potl-report-table'>
                                    <thead>
                                        <tr>
                                            <td>Name</td>
                                            <td>Room</td>
                                            <td>Notes</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {potl.filter(potl=>filterPotlReport(potl)).map((potl, index) =>
                                            <tr key={index}>
                                                <td>{potl.guest.preferredName} {potl.guest.lastName.slice(0, 3)}.</td>
                                                <td>{potl.room}</td>
                                                <td>{potl.offlandDates.length>0 ? <em>offland {dayjs(potl.offlandDates[0].startDate).isSame(dayjs(), 'day') ? "(leaving today)" : ""}{dayjs(potl.offlandDates[0].endDate).isSame(dayjs(), 'day') ? "(coming back today)" : ""}</em> : ''}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Fragment> : <LoadingSpinner />}
                </Tab>
                <Tab eventKey="offlandDatesReport" title="Offland Dates" className='offland-dates-tab'>
                    <div className='data-table-container'>
                        <div className='table-actions table-filters gap-1'>
                            <label className='filter-group'>From:</label> 
                            <div><DatePicker selected={odStartDate} onChange={(date) => {setOdStartDate(date); if (dayjs(date).isAfter(odEndDate)) setOdEndDate(dayjs(date).add(7, 'day').toDate()); }} dateFormat="dd MMM, yyyy" calendarStartDay={1} /></div>
                            <label className='filter-group'>To:</label> 
                            <div><DatePicker selected={odEndDate} onChange={(date) => setOdEndDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={odStartDate} /></div>
                        </div>
                        <Table striped bordered hover className='offland-dates-table'>
                            <thead>
                            <tr>
                                <td>Booking</td>
                                <td>Guest</td>
                                <td>Departure Date</td>
                                <td>Arrival Date</td>
                                <td># Days</td>
                            </tr>
                            </thead>
                            <tbody>
                                {offlandDates && offlandDates.length > 0 && offlandDates.map((od,index) =>
                                    <tr key={index}>
                                        <td><a href={`/bookings/${od.booking.id}`} target='_blank'>{od.booking.id}</a></td>
                                        <td>{`${od.booking.guest.preferredName} ${od.booking.guest.lastName.slice(0,3)}.`}</td>
                                        <td>{dayjs(od.startDate).format('DD MMM YYYY')}</td>
                                        <td>{dayjs(od.endDate).format('DD MMM YYYY')}</td>
                                        <td>{dayjs(od.endDate).startOf('day').diff(dayjs(od.startDate).startOf('day'), 'day')}</td>
                                    </tr>
                                )}
                                {offlandDates && offlandDates.length == 0 ? <tr><td colSpan="5">No offland dates for this date range.</td></tr> : <Fragment />}
                            </tbody>
                        </Table>
                    </div>
                </Tab>
                {isSuperAdmin() || isFinance() || isAdmin() || isReception() ?
                <Tab eventKey="occupancyReport" title="Occupancy Report" className='occupancy-report-tab'>
                    <div className='data-table-container'>
                        <div className='table-actions table-filters gap-1'>
                            <label className='filter-group'>From:</label> 
                            <div><DatePicker selected={occStartDate} onChange={(date) => {setOccStartDate(date); if (dayjs(date).isAfter(occEndDate)) setOccEndDate(dayjs(date).add(30, 'day').toDate()); }} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={dayjs('2023-05-01').toDate()} /></div>
                            <label className='filter-group'>To:</label> 
                            <div><DatePicker selected={occEndDate} onChange={(date) => setOccEndDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={occStartDate} /></div>
                            <div className='occ-type-filter'>
                                <Select options={occupancyTypeOptions} onChange={(occTypes) => (async ()=>setSelectedOccTypes(occTypes.map(l=>l.value)))()} placeholder="Team, Guest and Village" isMulti menuPortalTarget={document.body} styles={selectFilterStyles}/>
                            </div>
                            {loadingOccupancyReport ? <LoadingSpinner /> : <Fragment />}
                        </div>
                        <h3 className='mt-5'>Bookings by Room Type</h3>
                        <p className='mt-2'><i>This only takes into account confirmed bookings.</i></p>
                        <Table striped bordered hover className='occupancy-report-table'>
                            <thead>
                            <tr>
                                <td>Room Type</td>
                                <td align="right"># of Rooms</td>
                                <td align="right"># of Guests</td>
                                <td align="right">Days Booked</td>
                                <td align="right">Sales</td>
                                <td align="right">Occupancy %</td>
                                <td align="right">Avg Daily Rate</td>
                                <td align="right">Max Daily Rate</td>
                                <td align="right">% of Max Rate</td>
                            </tr>
                            </thead>
                            <tbody>
                                {occupancyReport && occupancyReport.roomTypeOccupancyReport.map((row,index) =>
                                    <tr key={index}>
                                        <td>{row.roomType}</td>
                                        <td align="right">{row.roomsCount}</td>
                                        <td align="right">{row.guestsCount}</td>
                                        <td align="right">{row.bookedDays}</td>
                                        <td align="right">{formatter.format(row.sales)}</td>
                                        <td align="right">{row.occupancyPercentage}%</td>
                                        <td align="right">€{row.avgPrice}</td>
                                        <td align="right">€{row.fullPrice}</td>
                                        <td align="right">{row.pricePercentage}%</td>
                                    </tr>
                                )}
                                {occupancyReport ? <tr>
                                    <td align="right" style={{fontWeight: 'bold'}}>Total:</td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{occupancyReport.roomTypeOccupancyReport.reduce((accum, row) => accum + row.roomsCount, 0)}</td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{occupancyReport.roomTypeOccupancyReport.reduce((accum, row) => accum + row.guestsCount, 0)}</td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{occupancyReport.roomTypeOccupancyReport.reduce((accum, row) => accum + row.bookedDays, 0)}</td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{formatter.format(occupancyReport.roomTypeOccupancyReport.reduce((accum, row) => accum + row.sales, 0))}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr> : <Fragment /> }
                            </tbody>                           
                        </Table>
                    </div>
                </Tab> : <Fragment />}
                {isSuperAdmin() || isFinance() || isAdmin() || isReception() ?
                <Tab eventKey="ratesReport" title="Rates Report" className='rates-report-tab'>
                    <div className='data-table-container'>
                        <div className='table-actions table-filters gap-1'>
                            <label className='filter-group'>From:</label> 
                            <div><DatePicker selected={ratesStartDate} onChange={(date) => {setRatesStartDate(date); if (dayjs(date).isAfter(ratesEndDate)) setRatesEndDate(dayjs(date).add(30, 'day').toDate()); }} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={dayjs('2023-05-01').toDate()} /></div>
                            <label className='filter-group'>To:</label> 
                            <div><DatePicker selected={ratesEndDate} onChange={(date) => setRatesEndDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={ratesStartDate} /></div>
                            <div className='rates-type-filter'>
                                <Select options={occupancyTypeOptions} onChange={(bookingTypes) => (async ()=>setSelectedRateTypes(bookingTypes.map(l=>l.value)))()} placeholder="Team, Guest and Village" isMulti menuPortalTarget={document.body} styles={selectFilterStyles}/>
                            </div>
                            {loadingRatesReport ? <LoadingSpinner /> : <Fragment />}
                        </div>
                        <h3 className='mt-5'>Bookings by Room Type and Rate</h3>
                        <p className='mt-2'><i>This only takes into account confirmed bookings.</i></p>
                        <Table striped bordered hover className='rates-report-table'>
                            <thead>
                            <tr>
                                <td>Room Type</td>
                                <td align="right">Rate Group</td>
                                <td align="right">Rate</td>
                                <td align="right"># of Rooms</td>
                                <td align="right"># of Guests</td>
                                <td align="right">Days Booked</td>
                                <td align="right">Sales</td>
                            </tr>
                            </thead>
                            <tbody>
                                {ratesReport && ratesReport.ratesReport.map((row,index) =>
                                    <tr key={index}>
                                        <td>{index==0 || row.roomType!=ratesReport.ratesReport[index-1].roomType ? row.roomType : ''}</td>
                                        <td align="right">{row.rateGroupName}</td>
                                        <td align="right">€{row.ratePrice} / {row.isMonthly ? 'month' : 'day'}</td>
                                        <td align="right">{row.roomsCount}</td>
                                        <td align="right">{row.guestsCount}</td>
                                        <td align="right">{row.bookedDays}</td>
                                        <td align="right">{formatter.format(row.sales)}</td>
                                    </tr>
                                )}
                                {ratesReport ? <tr>
                                    <td align="right" style={{fontWeight: 'bold'}}>Total:</td>
                                    <td></td>
                                    <td></td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{ratesReport.ratesReport.reduce((accum, row) => accum + row.roomsCount, 0)}</td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{ratesReport.ratesReport.reduce((accum, row) => accum + row.guestsCount, 0)}</td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{ratesReport.ratesReport.reduce((accum, row) => accum + row.bookedDays, 0)}</td>
                                    <td align="right" style={{fontWeight: 'bold'}}>{formatter.format(ratesReport.ratesReport.reduce((accum, row) => accum + row.sales, 0))}</td>
                                </tr> : <Fragment /> }
                            </tbody>                           
                        </Table>
                    </div>
                </Tab> : <Fragment />}                            
            </Tabs>
        </div>
        <ToastContainer />
      </div>
    )
  }